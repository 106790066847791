@import "../../styles/colors.scss";
@import "../../styles/constants.scss";

.loadingHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  
  @media screen and (max-width: $mobile-screen) {
    min-height: 300px;  
  }
}

.slugPage {
  margin-top: 75px;

  padding-left: 14vw;

  @media screen and (max-width: $small-screen) {
    margin-top: 60px;
    padding-left: 10%;
  }
  @media screen and (max-width: $mobile-screen) {
    padding-left: 5%;
  }
}